import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import Decrypt from "../pages/Decrypt";
import Dec2bin from "../pages/Dec2bin";
import Linechart3 from "../charts/LineChart3";
// import Barchart from "../charts/BarChart";
import FuelContainer from "../charts/FuelContainer";
import Volper from "../charts/Volper";

function Dashboard() {
  // Declare state variables and their setter functions using the useState hook
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0],
  );
  //TODO: FOR LOADING
  const [loading, setLoading] = useState(false);
  // Get an item named "sendmsg" from the localStorage and evaluate it as an object
  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");

  let name_list = JSON.parse(localStorage.getItem("name_list"));
  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  let location_list = JSON.parse(localStorage.getItem("location_list"));
  let project_name = JSON.parse(localStorage.getItem("project_name"));

  // Define some control messages as objects
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  // Declare and initialize a state variable 'ison' as false
  const [ison, setIson] = useState(false);

  // Declare state variables for storing data from local storage
  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);

  // Retrieve data from local storage and parse it into the respective state variables
  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);
  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  // console.log(resultlatest);

  // console.log(currentime);

  // Set up an interval that runs every 5000ms (5 seconds)
  setInterval(() => {
    // Retrieve data from local storage and parse it into the respective state variables
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

    // Update the state variables with the newly retrieved data
    setResultLatest(resultlatest);
    setMqttData(mqttdata);

    // Determine the value of 'ison' based on the retrieved data from mqttdata
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][39] === 1
          ? true
          : false
        : false,
    );
  }, 5000);
  // console.log(ison);

  // Define a function that handles the click event of a button
  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`,
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);
  //TODO: apis starts here - analytical data

  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  //TODO:LOOADIN
  const handleChange = (event) => {
    const confirmation = window.confirm(`ARE YOU SURE TO CHANGE THE DATE?`);
    if (confirmation) {
      setSelectedDate(event.target.value);
    }
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [RunningMinArr, setRunningMinArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  let [alertTime, setAlertTime] = useState([]);

  // todo: new array of params declaration below
  let [il1, setIl1] = useState([]);
  let [il2, setIl2] = useState([]);
  let [il3, setIl3] = useState([]);
  let [vl1, setVl1] = useState([]);
  let [vl2, setVl2] = useState([]);
  let [vl3, setVl3] = useState([]);
  let [activeE, setActiveE] = useState([]);
  let [apparentE, setApparentE] = useState([]);
  let [pf, setPf] = useState([]);
  let [fuelLevelArr, setFuelLevelArr] = useState([]);
  let [sensorVoltage, setSensorVoltage] = useState([]);
  let [litre, setLitre] = useState([]);

  // Define a function that converts a comma-separated string to an array
  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  // Declare and initialize a state variable 'currentTime' with the current time
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  function convertTo24Hour(timeString) {
    // Create a new Date object with the date set to "1970-01-01" and the time from the timeString
    const time = new Date("1970-01-01 " + timeString);

    // Get the hours from the time object
    let hours = time.getHours();

    // Determine if the time is in PM (after 12:00 PM)
    const isPM = hours >= 12;

    // Convert the hours to 12-hour format by taking the modulo 12
    // If it's PM, add 12 to the hours to convert to 24-hour format
    hours = (hours % 12) + (isPM ? 12 : 0);

    // Get the minutes from the time object and convert it to a string, padded with leading zeros if necessary
    const minutes = time.getMinutes().toString().padStart(2, "0");

    // Get the seconds from the time object and convert it to a string, padded with leading zeros if necessary
    const seconds = time.getSeconds().toString().padStart(2, "0");

    // Concatenate the hours, minutes, and seconds with ":" as the separator
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    // Return the converted time in 24-hour format
    return time24;
  }
  // console.log(convertTo24Hour(currentTime));
  let currentime = convertTo24Hour(
    JSON.parse(localStorage.getItem("currentime")),
  );
  // console.log(currentime);

  useEffect(() => {
    axios
      .post(
        "https://agromation.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers },
      )
      .then(function (response) {
        // console.log(response.data.data);

        // Code to handle the response

        // Access the data from the response
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);

        // Clear the timeArray
        timeArray = [];

        // Process each log entry
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;

          // Convert the log time to local time in the "Asia/Kolkata" time zone
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });

          // Convert the time to 24-hour format and push it to the timeArray
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;

          // Convert the message string to an array using the stringToArray function
          message.push(stringToArray(w));

          // Process the decrypted message array
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 1100; index++) {
            // TODO: NORMAL CONVERSION CODE BELOW
            // array_index = index * 2;
            // msgArray[index] =
            //   Number(message[i][array_index]) * 256 +
            //   Number(message[i][array_index + 1]);

            // dataArr[i] = msgArray;

            // TODO: LSRF CODE BELOW
            array_index = index * 4;
            let st = Number(message[i][array_index]);
            let nd = Number(message[i][++array_index]);
            let rd = Number(message[i][++array_index]);
            let th = Number(message[i][++array_index]);
            msgArray[index] = Decrypt(
              Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)),
            );
            dataArr[i] = msgArray;
            //console.log(dataArr[i]);
          }
        }

        ///TODO: Update the state variables with the processed data
        setTimeArray(timeArray);
        // console.log(timeArray);
        //console.log(dataArr);

        for (let i = 0; i < dataArr.length; i++) {
          // Assign the value at indexes in the current dataArr element divided by 100 to the corresponding index in avgPfArr,powerArr,vAvg and so on..
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = Number(dataArr[i][22]).toFixed(2);
          EnergyArr[i] = dataArr[i][0];
          RunningMinArr[i] = dataArr[i][60] * 60 + dataArr[i][61];
          // new
          fuelLevelArr[i] = (
            (((dataArr[i][595] - 660) / 165) * 62.5 + 250) *
            0.813
          ).toFixed(2);
          // mqttdata?.[panelinfo.cardid]?.[595]-660) /165))*62.5)*0.66
          sensorVoltage[i] = (dataArr[i][525] * 1.56).toFixed(2);
          litre[i] = ((1550 - dataArr[i][526] * 2.7142 * 0.8) * 0.01).toFixed(
            2,
          );

          // TODO: NEW PARAMETERS IN THIS PROJECT:
          il1[i] = dataArr[i][0];
          il2[i] = dataArr[i][1];
          il3[i] = dataArr[i][2];
          vl1[i] = dataArr[i][3];
          vl2[i] = dataArr[i][4];
          vl3[i] = dataArr[i][5];
          pf[i] = dataArr[i][6];
          activeE[i] = dataArr[i][7];
          apparentE[i] = dataArr[i][8];
        }

        setVavg(vAvg);
        setIAvg(iAvg);
        setPowerArr(powerArr);
        setEnergyArr(EnergyArr);
        setRunningMinArr(RunningMinArr);
        setAvgPfArr(avgPfArr);
        setFuelLevelArr(fuelLevelArr);
        setSensorVoltage(sensorVoltage);
        setLitre(litre);

        //todo: set values of arrays
        setIl1(il1);
        setIl2(il2);
        setIl3(il3);
        setVl1(vl1);
        setVl2(vl2);
        setVl3(vl3);
        setPf(pf);
        setActiveE(activeE);
        setApparentE(apparentE);
        // setAlertArr(alertArr);
        // console.log(iAvg);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  // console.log(EnergyArr)

  // console.log(alertArr);
  let extractedFaults = [];
  for (let i = 0; i < alertArr.length; i++) {
    const faultObj = alertArr[i];
    const time1 = faultObj[0].time;
    if (
      faultObj[0].vl1_fault === 1 ||
      faultObj[1].vl2_fault === 1 ||
      faultObj[2].vl3_fault === 1
    ) {
      extractedFaults.push({ fault: "under voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 1 ||
      faultObj[1].il2_fault === 1 ||
      faultObj[2].il3_fault === 1
    ) {
      extractedFaults.push({ fault: "under current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 2 ||
      faultObj[1].vl2_fault === 2 ||
      faultObj[2].vl3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 2 ||
      faultObj[1].il2_fault === 2 ||
      faultObj[2].il3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 3 ||
      faultObj[1].vl2_fault === 3 ||
      faultObj[2].vl3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 3 ||
      faultObj[1].il2_fault === 3 ||
      faultObj[2].il3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance current fault", time1 });
    }
  }
  // console.log(extractedFaults);
  if (extractedFaults) {
    localStorage.setItem("extractedFaults", JSON.stringify(extractedFaults));
  }

  let avgCvalueArray = [];
  let avgCtimeArray = [];
  //console.log(avgCvalueArray);
  //console.log(avgCtimeArray);

  let avgVvalueArray = [];
  let avgVtimeArray = [];

  let avgPFvalueArray = [];
  let avgPFtimeArray = [];

  let powervalueArray = [];
  let powertimeeArray = [];

  //TODO: BAR GRAPHS FOR THIS PROJECT
  let energyvalueArray = [];
  let energytimeArray = [];

  let currentCycleArray = [];
  let currentTimeArray = [];

  let fuelLevelValue20 = [];
  let fuelLevelTime20 = [];

  let sensorVoltageValue = [];
  let sensorVoltageTime = [];

  let litreValue = [];
  let litretime = [];

  // new optimized array - for good looking graph, we dont want 1400 logs every minute so we churn it into 30 mins
  for (let i = 0; i < timeArray.length / 10; i++) {
    // let trueIndex = 0;
    // for (let j = 0; j < 30; j++) {
    //   trueIndex = i * 30 + j;
    //   if (
    //     iAvg[trueIndex] - iAvg[trueIndex + 1] > 1 ||
    //     iAvg[trueIndex + 1] - iAvg[trueIndex] > 1
    //   ) {
    //     avgCvalueArray.push(iAvg[trueIndex]);
    //     avgCtimeArray.push(timeArray[trueIndex]);
    //   }
    //   if (
    //     vAvg[trueIndex] - vAvg[trueIndex + 1] > 1 ||
    //     vAvg[trueIndex + 1] - vAvg[trueIndex] > 1
    //   ) {
    //     avgVvalueArray.push(vAvg[trueIndex]);
    //     avgVtimeArray.push(timeArray[trueIndex]);
    //   }
    //   if (
    //     powerArr[trueIndex] - powerArr[trueIndex + 1] > 10 ||
    //     powerArr[trueIndex + 1] - powerArr[trueIndex] > 10
    //   ) {
    //     powervalueArray.push(powerArr[trueIndex]);
    //     powertimeeArray.push(timeArray[trueIndex]);
    //   }
    //   if (
    //     avgPfArr[trueIndex] - avgPfArr[trueIndex + 1] > 0.1 ||
    //     avgPfArr[trueIndex + 1] - avgPfArr[trueIndex] > 0.1
    //   ) {
    //     avgPFvalueArray.push(avgPfArr[trueIndex]);
    //     avgPFtimeArray.push(timeArray[trueIndex]);
    //   }
    // }
    let trueIndex1 = i * 10 + 10;
    // if (trueIndex1 == trueIndex) {
    //   trueIndex1++;
    //   console.log("hellow from true");
    // }
    if (trueIndex1 <= timeArray.length) {
      avgCvalueArray.push(iAvg[trueIndex1]);
      avgCtimeArray.push(timeArray[trueIndex1]);

      avgVvalueArray.push(vAvg[trueIndex1]);
      avgVtimeArray.push(timeArray[trueIndex1]);

      powervalueArray.push(powerArr[trueIndex1]);
      powertimeeArray.push(timeArray[trueIndex1]);

      avgPFvalueArray.push(avgPfArr[trueIndex1]);
      avgPFtimeArray.push(timeArray[trueIndex1]);

      fuelLevelValue20.push(fuelLevelArr[trueIndex1]);
      fuelLevelTime20.push(timeArray[trueIndex1]);

      energyvalueArray.push(EnergyArr[trueIndex1]);
      energytimeArray.push(timeArray[trueIndex1]);

      currentCycleArray.push(iAvg[trueIndex1]);
      currentTimeArray.push(timeArray[trueIndex1]);

      sensorVoltageValue.push(sensorVoltage[trueIndex1]);
      sensorVoltageTime.push(timeArray[trueIndex1]);

      litreValue.push(litre[trueIndex1]);
      litretime.push(timeArray[trueIndex1]);
    }
  }
  // console.log(timeArray);


  // console.log(currentCycleArray);
  // console.log(currentTimeArray);


  // console.log(fuelLevelValue20);
  // console.log(fuelLevelTime20);
  // console.log(sensorVoltageValue);
  // console.log(sensorVoltageTime);
  // console.log(litreValue);
  // console.log(litretime);

  // todo: 5-5 minute arrays to reduce the data points for plotting graph
  let il15min = [];
  let il25min = [];
  let il35min = [];
  let vl15min = [];
  let vl25min = [];
  let vl35min = [];
  let pf5min = [];
  let time5min = [];
  for (let i = 0; i < timeArray.length / 5; i++) {
    let trueIndex = 0;
    //todo: suppose 20 min then multiply by 20
    trueIndex = i * 5;
    il15min.push(il1[trueIndex]);
    il25min.push(il2[trueIndex]);
    il35min.push(il3[trueIndex]);
    vl15min.push(vl1[trueIndex]);
    vl25min.push(vl2[trueIndex]);
    vl35min.push(vl3[trueIndex]);
    pf5min.push(pf[trueIndex]);
    time5min.push(timeArray[trueIndex]);
  }

  // todo: active and apparent hourly
  let ActiveEtimeHour = [];
  let ActiveEPerHour = [];

  for (let i = 0; i < activeE.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, activeE.length - 1);
    ActiveEPerHour.push(activeE[end] - activeE[start]);
    ActiveEtimeHour.push(timeArray[end]);
  }
  // console.log(timeHour);
  // console.log(energyPerHour);
  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  // TIMER-1 PERCENTAGE

  let apparentEtimeHour = [];
  let apparentEPerHour = [];

  for (let i = 0; i < apparentE.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, apparentE.length - 1);
    apparentEPerHour.push(apparentE[end] - apparentE[start]);
    apparentEtimeHour.push(timeArray[end]);
  }

  //todo: pie cart real time values
  let realCurrentIL1 = mqttdata?.[panelinfo.cardid]
    ? Number(mqttdata[panelinfo.cardid][0]) + 0.1
    : 0.1;
  let realCurrentIL2 = mqttdata?.[panelinfo.cardid]
    ? Number(mqttdata[panelinfo.cardid][1]) + 0.1
    : 0.1;
  let realCurrentIL3 = mqttdata?.[panelinfo.cardid]
    ? Number(mqttdata[panelinfo.cardid][2]) + 0.1
    : 0.1;
  let realVoltageVL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][3] + 10
    : 1;
  let realVoltageVL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][4] + 10
    : 1;
  let realVoltageVL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][5] + 10
    : 1;
  // console.log(mqttdata?.[panelinfo.cardid]
  //   ? mqttdata[panelinfo.cardid][526]*2.7142
  //   : 0);

  // sensor voltage
  // console.log((mqttdata[panelinfo.cardid][595]-660))
  //TODO: running minutes
  let RunningMin = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][60] * 60 + mqttdata[panelinfo.cardid][61]
    : 0;
  // console.log(RunningMin)

  //todo: below is fuel content in litre
  let fuelContent = mqttdata?.[panelinfo.cardid]
    ? (((mqttdata[panelinfo.cardid][595] - 660) / 165) * 62.5).toFixed(2)
    : 0;

  //TODO: ENERGY
  let len = EnergyArr.length;
  let EnergyConsumed = EnergyArr[len - 1] - EnergyArr[0];
  //console.log(EnergyConsumed);


  //TODO: current - Function to count the number of current cycles
 


  const countNumbers = (arr) => {
    let count = 0;
    let inCycle = false;

    arr.forEach((num) => {
        if (num > 1 && !inCycle) {
            // Start of a new cycle
            count++;
            inCycle = true;
        } else if (num <= 1 && inCycle) {
            // End of the current cycle
            inCycle = false;
        }
    });

    return count;
};
  let currentCycleCount = countNumbers(iAvg);

  const NaNToZero = (arr) => {
    return arr.map(value => value === "NaN" ? 0 : value);

};

let current = NaNToZero(iAvg)
// console.log(current);
const countCyclesBetweenZeros = (arr) => {
  let count = 0;
  let inCycle = false;

  // Iterate through the array
  for (let i = 0; i < arr.length; i++) {
      const value = arr[i];

      if (value > 0) {
          // We are inside a positive value segment
          if (!inCycle) {
              // Start of a new cycle
              inCycle = true;
          }
      } else if (value === 0) {
          // We are at a zero
          if (inCycle) {
              // End of a cycle if we were inside one
              count++;
              inCycle = false; // Reset for the next potential cycle
          }
      }
  }

  return count;
};


const cycleCount = countCyclesBetweenZeros(current);

// console.log('Number of Cycles:', cycleCount);




  // console.log('Final Cycle Count:', currentCycleCount);
  // console.log(currentCycleCount);

  // console.log(iAvg)
  

  // console.log(RunningMin);

  //console.log(fuelContent);

  return (
    <div className="flex h-screen overflow-hidden bg-[#3d0f34] ">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 flex flex-wrap items-center justify-end gap-2 p-1 bg-[#3d0f34] ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label
              htmlFor="datepicker"
              className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>

          {/* <div>
            <Link to="/Table">
              <button className="px-3 py-2 mt-2 text-black bg-white border rounded-lg shadow-lg shadow-gray-600 sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60">
                Configuration
              </button>
            </Link>
          </div> */}
          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            {/* <button>Latest Data Arrival Time: {currentime}</button> */}
            <WelcomeBanner />

            {/* statuses cards */}
            {/* status ends here */}

            {/* //value Cards  */}
            <div className="flex flex-col flex-wrap p-4 mb-2 bg-white rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-indigo-500/50 ">
              {/* voltage & CURRENT divs - 8 */}
              <div className="flex flex-wrap "></div>

              {/* extra info cards */}
              <div className="flex flex-wrap ">
                {/* fuel level tank */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  {/* <div className="mb-2 text-lg font-bold text-center">
                    Fuel Level
                  </div> */}
                {/* <div>
                    <FuelContainer
                      fuelLevel={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][526] * 2.7142).toFixed(
                              2,
                            )
                          : 0
                      }
                    />
                  </div> */}
                {/* <div>
                    <Volper
                      fuelcontentinL={fuelContent}
                    />
                  </div>
                </div> */}
                {/* //fuel content */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  <div className="text-2xl font-bold">
                    <p className="uppercase">Today's Energy Consumption</p>
                    <br />
                    <p>{EnergyConsumed}</p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  <div className="text-2xl font-bold">
                    <p className="uppercase">Today's Running Minutes</p>
                    <br />
                    <p>{RunningMin}</p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  <div className="text-2xl font-bold">
                    <p className="uppercase">
                      {" "}
                      Today's No. Of cycles count(Current)
                    </p>
                    <br />
                    <p>{cycleCount}</p>
                  </div>
                </div>
                {/* TANK DEPTH */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  <div className="mb-2 text-lg font-bold text-center">
                    Tank Depth(1550)
                  </div>
                  <div>
                    <span className="text-2xl font-bold">
                      {/* {1550-(mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][526]*2.7142*0.8).toFixed(2)
                        : 0)} */}
                {/* 1550mm */}
                {/* </span> */}
                {/* </div> */}
                {/* </div> */}
                {/* VCC */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 text-white transition duration-150 transform rounded-lg l f fle btn hover:bg-indigo bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-600 hover:shadow-2xl hover:bg-indigo-500 hover:text-white ">
                  <div className="mb-2 text-lg font-bold text-center">
                    Sensor Voltage
                  </div>
                  <div>
                    <span className="text-2xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][525] * 1.56).toFixed(2)
                        : 0}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            {/* value ends here */}

            {/* chart Cards */}
            <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              {/* <div className="flex-1 h-full p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE VOLTAGE
                </div>
                <PieChart
                  value1Label="VL1"
                  value2Label="VL2"
                  value3Label="VL3"
                  tag="VOLTAGE"
                  value1={realVoltageVL1}
                  value2={realVoltageVL2}
                  value3={realVoltageVL3}
                />
              </div> */}

              {/* <div className="flex-1 h-full p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE CURRENT
                </div>
                <PieChart
                  value1Label="IL1"
                  value2Label="IL2"
                  value3Label="IL3"
                  tag="CURRENT"
                  value1={realCurrentIL1}
                  value2={realCurrentIL2}
                  value3={realCurrentIL3}
                />
              </div> */}
            </div>
            {/* <div className="p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
              <div className="p-1 font-sans text-lg font-bold text-center">
                REAL TIME TIMER STATUS
              </div>
              <ProgressChart
                per1={Timer1per}
                per2={Timer2per}
                per3={Timer3per}
                per4={Timer4per}
                per5={Timer5per}
                per6={Timer6per}
                per7={Timer7per}
                per8={Timer8per}
                per9={Timer9per}
                per10={Timer10per}
              />
            </div> */}
            {/* iavg trend  */}

            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2"> */}
            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  FUEL LEVEL TREND
                </div>
                <Linechart
                  dataArr={fuelLevelValue20}
                  timeArr={fuelLevelTime20}
                  divider={1}
                  tag="Fuel Level"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                  style={{ height: "100%" }}
                />
              </div> */}

            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  SENSOR VOLTAGE TREND
                </div>
                <Linechart
                  dataArr={sensorVoltageValue}
                  timeArr={sensorVoltageTime}
                  divider={1}
                  tag="Sensor Voltage"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                  style={{ height: "100%" }}
                />
              </div> */}

            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
                <div className="p-2 font-sans text-xl font-bold text-center">
                 LITRE TREND
                </div>
                <Linechart
                  dataArr={litreValue}
                  timeArr={litretime}
                  divider={1}
                  tag="Litre trend"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                  style={{ height: "100%" }}
                />
              </div> */}

            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  V<sub>avg</sub> Trend
                </div>
                <Linechart
                  dataArr={avgVvalueArray}
                  timeArr={avgVtimeArray}
                  divider={5}
                  tag="Average Voltage"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div> */}
            {/* </div> */}

            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2"> */}
            {/* power trend  */}
            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Voltage Trend
                </div>
                <Linechart3
                  dataArr1={vl15min}
                  dataArr2={vl25min}
                  dataArr3={vl35min}
                  timeArr={time5min}
                  divider={1}
                  tag1="VL1"
                  tag2="VL2"
                  tag3="VL3"
                  colour1="rgb(255,0,0)"
                  colour2="rgb(255,255,0)"
                  colour3="rgb(0,0,225)"
                  min={0}
                  max={1}
                  date={selectedDate}
                  tag="Voltage"
                  name={name_list[panelinfo.cardid]}
                />
              </div> */}

            {/* power factor avg trend   */}

            {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Current Trend
                </div>
                <Linechart3
                  dataArr1={il15min}
                  dataArr2={il25min}
                  dataArr3={il35min}
                  timeArr={time5min}
                  divider={1}
                  tag1="IL1"
                  tag2="IL2"
                  tag3="IL3"
                  colour1="rgb(255,0,0)"
                  colour2="rgb(255,255,0)"
                  colour3="rgb(0,0,225)"
                  min={0}
                  max={1}
                  date={selectedDate}
                  tag="Current"
                  name={name_list[panelinfo.cardid]}
                />
              </div> */}
            {/* </div> */}
            <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              {/* energy per hour trend */}
              <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  TODAY'S ENERGY CONSUMPTION BAR GRAPH
                </div>
                <ProgressChart
                  dataArr={energyvalueArray}
                  timeArr={energytimeArray}
                  colour={"#00009B"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Energy Consumption"}
                  style={{ height: "100%" }}
                />
              </div>

              {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
                <div className="p-2 font-sans text-xl font-bold text-center">
                  TODAY'S NO OF CYCLE COUNT(CURRENT) BAR GRAPH
                </div>
                <ProgressChart
                  dataArr={currentCycleArray}
                  timeArr={currentTimeArray}
                  colour={"#00009B"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Current Count"}
                  style={{ height: "100%" }}
                />
              </div> */}
           
              {/* <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  APPARENT ENERGY<sub> / Hour</sub>
                </div>
                <ProgressChart
                  dataArr={apparentEPerHour}
                  timeArr={apparentEtimeHour}
                  colour={"#00008B"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Apparent Energy hourly"}
                />
              </div> */}
            </div>

            {/* <div className="flex-1 h-full p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50">
              <div className="p-1 font-sans text-sm font-bold text-center">
                POWER FACTOR TREND
              </div>
              <Linechart
                dataArr={pf5min}
                timeArr={time5min}
                divider={1}
                tag="PF"
                colour="rgb(0,0,225)"
                min={0}
                max={1}
                date={selectedDate}
                name={name_list[panelinfo.cardid]}
              />
            </div> */}

            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
