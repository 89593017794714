import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState, handleClick } from "react";
// import WelcomeBanner from "../partials/dashboard/WelcomeBanner";

// import "animate.css";
// import Typewriter from "react-typewriter-effect";

// import logo from "/src/logo192.png";

const Login = () => {
  let [loginToken, setLoginToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [project_ids, setProjectids] = useState([]);
  let [project_name, setProjectName] = useState([]);
  let [device_ids, setDeviceids] = useState([]);
  let [imeilist, setImeilist] = useState([]);
  let [location_list, setLocationList] = useState([]);
  let [name_list, setNameList] = useState([]);
  let [headers, setHeaders] = useState({});
  let [token, setToken] = useState("");
  const navigate = useNavigate();

  /// When a user enters their username and password and clicks the login button, an event called "handleSubmit" is triggered
  const handleSubmit = (event) => {
    /// Inside the "handleSubmit" function, the code prevents the default behavior of form submission (which would refresh the page).
    event.preventDefault();

    //navigate

    /// It then sends a request to a login API endpoint with the entered username and password using the axios library. This API endpoint is responsible for authenticating the user.

    // if (username === allowedUser && password === allowedPassword) {

    axios
      .post("https://agromation.smart-iot.in/api/super-admin/login", {
        // username: "Shubham",
        // password: "Shubham@123",
        // remember: true,
        // domain: "vidani",
        username: username,
        password: password,
        remember: true,
        domain: "agromation",
      })
      .then(function (response) {
        console.log(response.data.message);
        // console.log(response.data.data);
        token = response.data.data;
        // If the login is successful, the server responds with a login token, which is a unique identifier for the user's session. The code retrieves this token from the response.

        // The login token is stored in the component's state variable called "loginToken" and also in the browser's localStorage, so it can be accessed later even if the page is refreshed.
        loginToken = response.data.data;
        loginToken = localStorage.setItem(
          "loginToken",
          JSON.stringify(loginToken),
        );
        console.log(localStorage.getItem("loginToken"));
        setLoginToken(loginToken);
        console.log(token);
        setToken(token);
        // The code then sets the login token in the request headers, which will be used for authorization in subsequent API requests.
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        console.log(headers);
        setHeaders(headers);
        // console.log(headers);
        //   navigate({
        //     pathname: "/Front.js",
        //     state: { loginToken: loginToken },
        //   });

        // The code makes another API request to fetch the list of projects associated with the user's account. It includes the login token in the request headers to authenticate the request.
        axios
          .get(
            "https://agromation.smart-iot.in//api/super-admin/project/getallprojects/4",
            { headers },
          )
          .then(function (response) {
            console.log(response.data);
            console.log(response.data.data.projects[0].id);
            let x = response.data.data.projects;
            console.log(x);
            let loop = x.length;
            console.log(loop);

            // Once the projects are fetched successfully, the code extracts the project IDs and project names from the response and stores them in separate state variables.

            project_ids = [];
            project_name = [];
            for (let i = 0; i < loop; i++) {
              project_ids[i] = x[i].id;
              project_name[i] = x[i].name;
            }
            console.log(project_ids);
            setProjectids(project_ids);
            setProjectName(project_name);
            console.log(project_name);

            if (project_name) {
              localStorage.setItem(
                "project_name",
                JSON.stringify(project_name),
              );
            }
            // The code makes another API request to fetch device information associated with the first project in the list. It includes the login token in the request headers for authorization.

            //change
            axios
              .get(
                `https://agromation.smart-iot.in/api/super-admin/project/getallprojectdevices/4/${project_ids[0]}`,
                { headers },
              )
              .then(function (response) {
                console.log(response.data);
                console.log(response.data.data.devices[0].imei);
                console.log(response.data.data.devices[0].location);
                let y = response.data.data.devices;
                console.log(y.length);
                // Once the device information is fetched successfully, the code extracts device IDs, IMEIs, locations, and names from the response and stores them in separate state variables.
                for (let i = 0; i < y.length; i++) {
                  imeilist[i] = y[i].imei;
                  device_ids[i] = y[i].id;
                  location_list[i] = y[i].location;
                  name_list[i] = y[i].name;
                }
                console.log(imeilist);
                console.log(device_ids);
                setDeviceids(device_ids);
                setImeilist(imeilist);
                setLocationList(location_list);
                setNameList(name_list);
                if (imeilist) {
                  localStorage.setItem("imeilist", JSON.stringify(imeilist));
                }
                if (device_ids) {
                  localStorage.setItem(
                    "device_ids",
                    JSON.stringify(device_ids),
                  );
                }
                if (location_list) {
                  localStorage.setItem(
                    "location_list",
                    JSON.stringify(location_list),
                  );
                }
                if (name_list) {
                  localStorage.setItem("name_list", JSON.stringify(name_list));
                }
                // If all the necessary data is retrieved successfully, the code navigates to the home page ("/Home") using the React Router library. It passes the login token as a parameter in the route's state, which can be accessed by the home page component.
                navigate({
                  pathname: "/Home",
                  state: { loginToken: loginToken },
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // localStorage.clear();

  // The return statement in the code defines the UI of the login page using JSX. It includes input fields for username and password, a login button, and some styling.
  return (
    <div className="overflow-hidden bg-[#250719]">
      {/* <div className="flex items-center justify-center p-10 m-3 ">
        <div
          className="mb-6 font-serif text-4xl text-center text-white animate__animated animate__fadeInLeft "
          style={{
            animationDuration: "5s",
            animationFillMode: "forwards",
            animationIterationCount: "1",
            animationTimingFunction: "ease-out",
          }}
        >
          Temperature Monitoring System
        </div>
      </div> */}
      <div className="relative flex flex-wrap items-center justify-center h-screen overflow-hidden ">
        {/* <div> Temperature Monitoring System</div> */}
        <form
          className="flex items-center justify-center"
          onSubmit={handleSubmit}>
          <div className="items-center p-6 bg-[#9b276c]  rounded-lg shadow-lg shadow-black/50 backdrop-filter backdrop-blur-lg w-60 sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3">
            <div className="flex items-center justify-center mt-3 mb-6">
              <img
                src="/images/agromation.jpg"
                alt="BSS logo"
                className="w-32 h-32 rounded-lg"
              />
            </div>
            {/* <WelcomeBanner /> */}
            <label className="font-mono text-lg text-gray-100">Username</label>
            <input
              className="w-full px-1 py-2 mb-4 text-gray-800 bg-gray-100 rounded-lg shadow-lg outline-none"
              type="text"
              placeholder="Enter your Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}></input>
            <label className="font-mono text-lg text-gray-100">Password</label>
            <input
              className="w-full px-1 py-2 mb-4 text-black bg-gray-100 rounded-lg shadow-lg outline-none shadow-black/50"
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}></input>
            {/* <Link to="/Container.js"> */}
            <button
              // onClick = {() => localStorage.setItem()}
              type="submit"
              className="w-full py-2 mb-3 font-mono text-lg text-white transition-colors bg-blue-500 rounded-lg hover:bg-blue-800">
              Login
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;


// Overall, the code handles the login process, retrieves necessary data from APIs, and navigates to the home page if the login is successful.