import React from "react";

function WelcomeBanner() {
  return (
    <div className="relative p-4 overflow-hidden rounded-sm m sm:p-6">
      {/* Content */}
      <div className="relative">
        <h1 className="p-4 mb-1 font-sans text-2xl font-bold text-center text-black bg-white bg-opacity-50 rounded-lg shadow-xs md:text-3xl backdrop-filter backdrop-blur-lg">
           SMART TANKER COUNTER 
        </h1>
        <p className="font-sans text-2xl text-center text-white font-poppins ">
          Data Analytics
        </p>
      </div>

      {/* //glassmorphism effect example */}
      {/* <div class="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4">
        <div class="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg shadow-md p-4">
          <div class="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-lg shadow-sm p-4">
            <div class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-lg shadow-xs p-4">
              4G PUMP HEALTH MONITORING SYSTEM 👋
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default WelcomeBanner;
