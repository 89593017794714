import React from "react";
import { Bar } from "react-chartjs-2";

const ProgressChart = ({ dataArr, timeArr, colour, date, name, tag }) => {
  const data = {
    labels: timeArr,
    datasets: [
      {
        label: tag,
        data: dataArr,
        backgroundColor: colour,
      },
    ],
  };

  const options = {
    indexAxis: "x", // Set the index axis to "y" for a vertical bar chart
    scales: {
      x: {
        beginAtZero: true,
        max: 100, // Adjust the maximum value as per your requirements
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const convertToCsv = (dataArr, timeArr) => {
    const headers = ["Time", "Data"];
    const rows = timeArr.map((t, i) => [t, dataArr[i]]);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");
    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", `${date}_${tag}_${name}}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="p-2 bg-gray-100 bg-opacity-100 border border-gray-300 border-opacity-50 rounded-lg backdrop-filter backdrop-blur-lg">
      <div className="flex justify-end m-2">
        <button
          className="btn font-inter bg-[#d8ee11] text-black"
          onClick={() => convertToCsv(dataArr, timeArr)}
        >
          Download
        </button>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProgressChart;
