import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
import Smsalert from "./Smsalert";

// this declares the Home component as a functional component using an arrow function. It initializes some variables (cards and options) and retrieves data from the local storage (disconnectString and mqttdata) using localStorage.getItem.
const Home = () => {
  const cards = [];
  const options = [];

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  // sets up a setInterval function that runs every 2000 milliseconds (2 seconds). Inside the interval, it fetches the mqttdata from local storage and updates the mqttdata state using the setMqttData function.

  // Note that using setInterval inside a component is not the recommended approach. It should be placed inside a useEffect hook.
  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
    // }, [mqttdata]);
  }, 2000);
  // console.log(mqttdata);

  /// These lines retrieve various data from local storage and parse it as JSON. The data is stored in variables such as imeilist, device_ids, location_list, name_list, and project_name.

  //WE HAVE TAKEN ALL THESE FROM LOGIN PAGE OR USER TABLE
  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  let project_name = JSON.parse(localStorage.getItem("project_name"));

  // This function, handleClick, is defined to handle the click event on a link in a card. It takes imei, deviceid, and cardid as parameters, creates a data object with the provided values, and stores it in the local storage as a string using localStorage.setItem.
  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  // This for loop iterates over the imeilist array and generates options and cards based on its length. For each iteration, it creates an option object and pushes it to the options array. It also generates a card component using JSX code and pushes it to the cards array.
  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className="flex-grow w-auto h-auto max-w-sm max-h-full p-5 m-2 mt-2 overflow-hidden transition-shadow ease-in bg-[#f8c5bc] rounded-lg shadow-lg cursor-pointer sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 hover:shadow-4xl hover:shadow-stone-400 backdrop-filter backdrop-blur-lg">
        <div className="mb-2 border-b-4 border-blue-950">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
            <h2 className="pb-3 font-mono text-xl font-semibold text-center text-BLACK">
              {/* {imeilist[i]} */}
              location: {location_list[i]}
            </h2>
            <h2 className="pb-3 font-mono text-xl font-semibold text-center text-BLACK">
              {/* {imeilist[i]} */}
              {name_list[i]}
            </h2>
            <h2 className="pb-3 font-mono text-sm font-semibold text-center text-BLACK">
              {/* {imeilist[i]} */}( {imeilist[i]} )
            </h2>
          </Link>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          {/* <p className="p-3 m-1 bg-[#3d0f34]  rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-white font-bold text-center flex-1 text-sm ">
            FUEL LEVEL
            <div>
              {(
                1550 -
                (mqttdata?.[i] ? (mqttdata[i][526] * 2.7142).toFixed(2) : 0)
              ).toFixed(2)}{" "}
              mm
            </div>
          </p> */}
          {/* <p className="p-3 m-1 bg-[#3d0f34]  rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-white font-bold text-center flex-1 text-sm ">
            SENSOR VOLTAGE
            <div>{mqttdata?.[i] ? (mqttdata[i][525]*1.56).toFixed(1) : 0} V</div>
          </p> */}
          {/* <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][5] : 0} V
            </p>
          </p> */}
        </div>

        {/* <div className="flex flex-wrap items-center justify-center ">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>1</sub>{" "}
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-xl border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][0] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][1] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-blue-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][2] : 0} A
            </p>
          </p>
        </div> */}

        {/* <div className="flex flex-wrap">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            Active Energy{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][7] : 0} KWh
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
          Apparent Energy{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][8] : 0} KVah
            </p>
          </p>
        </div> */}

        <Link
          to="/Dashboard"
          onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
          <div className="flex items-center justify-center mt-2">
            <button className="p-1 font-sans text-lg text-center text-black bg-yellow-400 rounded-lg">
              Show more...
            </button>
          </div>
        </Link>

        {/* <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-1 m-1 justify-center  bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center text-sm `}
          >
            MOTOR STATUS
            <button
              className={`flex items-center flex-1 p-2 m-1 justify-center rounded-lg shadow-md ${
                (mqttdata?.[i] ? mqttdata[i][39] : 0 === 1)
                  ? "bg-green-400 text-white hover:bg-green-500"
                  : "bg-red-500 text-white hover:bg-red-600"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdata?.[i] ? mqttdata[i][39] : 0 === 1) ? "ON" : "OFF"}
            </button>
          </p>
        </div> */}
      </div>,
    );
  }

  // function, scrolltoCard, is defined to scroll the page to a specific card based on its value. It retrieves the element with the corresponding id using document.getElementById and scrolls it into view using the scrollIntoView method with the "smooth" behavior
  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  // defines the handleChange function, which is an event handler for the Select component. It takes the selectedOption as a parameter, extracts the value, and calls the scrolltoCard function with the value.
  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  // This section returns the JSX code to be rendered. It includes a navigation bar, a container with a background gradient, and a div wrapping the cards array. There's also a commented-out section for rendering the Smsalert component.
  return (
    <div className="mt-0 ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-[#3d0f34] text-center font-sans font-semibold  justify-center flex-wrap gap-6">
        <nav className="text-xl text-white">SMART TANKER COUNTER</nav>
        <div className="">
          <Select
            options={options}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="min-h-screen overflow-hidden bg-[#3d0f34]">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>
    </div>
  );
};

export default Home;
